<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  }
}
</script>

<template>
  <div class="">
    <h2 class="text-2xl mb-4 font-bold text-gray-900">
      {{ title }}
    </h2>
    <div class="mt-3">
      <slot />
    </div>
  </div>
</template>
